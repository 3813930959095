<!--  -->
<template>
    <div class='page-cabin-summary flex-center'>
        <component-bcg url="cabin/c1.png"></component-bcg>
        <div class="bcg">
            <div class="page-left"></div>
            <div class="page-connect"></div>
            <div class="page-title">
                <div class="flex-center title">
                    <span class="text-large-b bold text-blue-color">{{"知识小屋"}}</span>
                </div>
            </div>
            <div class="page-go">
                <div class="head-item-x" :class="[`item-${v.key}`, {'active':v.courseId == selectCul.courseId}]" v-for="(v,i) in headListCul" :key="i" @click="tab(v.courseId)">
                    <div class="flex-center title">
                        <span class="bold text-red-color text-box text-normal-c" >{{v.videoName || v.value}}</span>
                    </div>
                </div>
            </div>
            <div class="page-center">
                <div class="page-bottom-shadow flex-center">
                    <div class="content-box mini-scrollbar" v-if="lessonCount">
                        <div :class="{'list-shadow':v.length}" v-for="(v,i) in listInfo" :key="i">
                            <div class="list-box">
                                <div class="list-box-left"></div>
                                <div class="list-box-right"></div>
                                <div class="title cursor-pointer" 
                                @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                                @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                                @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                                @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                                @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                                @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                                v-for="(t,j) in v" :key="j" @click="goTuVideo(t.townCourseLesson)">
                                    <div :class="[{'list-left':j%2==0,'list-right':j%2==1},{on:t.townUserLessonRecord.learnProcess}]">
                                        <span 
                                        class="text-deepred-color bold" 
                                        :class="{'l-rate':j%2==0,'r-rate':j%2==1}" 
                                        v-if="t.townUserLessonRecord.learnProcess">
                                            {{t.townUserLessonRecord.learnProcess}}%
                                        </span>
                                        <span 
                                        class="text-grey-color bold" 
                                        :class="{'l-txt':j%2==0,'r-txt':j%2==1}" 
                                        v-if="!t.townUserLessonRecord.learnProcess"
                                        >
                                            {{"未学习"}}
                                        </span>
                                        <span 
                                        class="text-deepred-color bold" 
                                        :class="{'l-txt':j%2==0,'r-txt':j%2==1}" 
                                        v-else-if="t.townUserLessonRecord.learnProcess<100"
                                        >
                                            {{"已学"}}
                                        </span>
                                        <span 
                                        class="text-deepred-color bold" 
                                        :class="{'l-txt':j%2==0,'r-txt':j%2==1}" 
                                        v-else
                                        >
                                            {{"已完成"}}
                                        </span>
                                    </div>
                                    <span class="bold txt hide-point-txt text-center">{{t.townCourseLesson.lessonName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <!-- <span class="text-normal bold text-yel-color">{{"当前知识胶囊尚未解锁，敬请期待"}}</span> -->
                        <span class="text-normal bold text-yel-color">{{listNotice}}</span>
                    </div>
                </div>
            </div>
            <div class="page-right">
                <div class="right-go"></div>
            </div>
        </div>
        <!-- <div class="debug-info">
            <span class="text-mini" v-html="debugInfo"></span>
        </div> -->
    </div>
</template>

<script>
    import apiClass from '@/API/page/cabin'
    import ComponentBcg from '../../../components/componentBcg.vue'
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                appData: new this.appDataClass({key: 'app'}),
                api: new apiClass(),
                headVal: this.$route.params.headVal,
                hash: this.$route.hash,
                goTabs:[
                    {title: "围棋乐园",label:"G0",bcg:"go-one"},
                    {title: "Go1",label:"G1",bcg:"go-two"},
                    {title: "Go2",label:"G2",bcg:"go-three"},
                ],
                listInfo: [],
                page: 0,
                size: 2,
                total: 0,
                lessonCount: 0,
                clazzNoList: [],
                // clazzNo: null,
                jump_errToast: null,
                jumpParams: null,
                config: {},
                dataLoading: false,
                configList: [],
                loadingTimeout: null,
            };
        },
        methods: {
            pageInit(){
                this.eventSet()
                this.configLoad()
            },
            async configLoad(){
                await this.configResourceLoad()
                await this.linkInit()
                this.headValCheck()
            },
            async configResourceLoad(){
                // let res = await this.api.configLoad('puzzle')
                let res = await this.api.configLoad('video')
                if(!res) return
                this.configList = res
            },
            async tab(val){
                this.refreshPage(val)
            },
            async headValCheck(){
                let {selectCul: {courseId} = {}} = this
                if(!courseId) courseId = this.headListCul[0].courseId
                this.refreshPage(courseId)
            },
            async refreshPage(val, opt){
                opt = opt || {}
                let name = 'cabin-summary'
                let type = 'replace'
                let params = {headVal: val}
                this._routeHand.goto({name, type, params, ...opt})
                await this._common.nextTick.call(this)
                return true
            },
            async levelChange(val){
                this.refreshPage(val)
            },
            async stepInfoLoad(exOpt){
                exOpt = exOpt || {}
                if(this.vedioDisabled) return
                let {selectCul: {courseId: course_id, size},usercode: user_code } = this
                // 内容加载
                if(!course_id) return
                let opt = {
                    course_id,
                    user_code,
                    page: 0,
                    size
                }
                this.dataLoading = true
                let res = await this.api.getCourseLessonRecord({...opt, ...exOpt})
                this.dataLoading = false
                if(!res) return
                let {lessonCount,lessonProgressList:list,clazzNoList, lessonRewardMap} = res || {}
                this.awardNotice(lessonRewardMap)

                this.lessonCount = lessonCount
                this.clazzNoList = clazzNoList
                // this.clazzNo = clazzNoList[0]
                this.listInfo = this.splitArr(list,2)
            },
            goTuVideo(val){
                let { clazzNo } = this
                if(clazzNo === null){
                    let message = this.pageText['no clazz number']
                    this._common.confirmPro.open({
                        message,
                        buttons: [
                            {title: '确定', type: 'confirm'}
                        ],
                        confirmType: 'c3'
                    })
                }
                if(!this._common.clientAppCheck('gaotu') && !this.isPCClient){
                    this._common.confirmPro.open({
                        message: this.TEXT.share['share client error'],
                        confirmType: 'c3',
                        buttons: [
                            {title: '确定', type: 'confirm'}
                        ]
                    })
                    return 
                }
                this.jumpAppAction(val)
            },
            jumpAppAction(val){
                let client = this._common.getClient()
                if(this.isPCClient) client = 'PC'
                let fun = `jump_${client}`
                if(!this[fun]) return
                this[fun](val)
            },
            jump_PC(val){
                let {link, hash} = this.PCLinkeData
                if(!link) return false
                let {lessonNo: liveId, lessonType: actionType} = val
                link = `${link}?liveId=${liveId}&actionType=${actionType}#${hash}`
                window.location.href = link
            },
            jump_Android(val){
                let {lessonType, lessonNo} = val
                let params = JSON.stringify({
                    live_id: lessonNo,
                    action_type: lessonType,// 1看直播/2看回放/3视频课(类直播)
                })
                this.jumpParams = params
                try{
                    window.gaotuJSBridge.showNativeLiving(params)
                }catch(e){ 
                    this.jump_err()
                }
            },
            jump_IOS(val){
                let {lessonType, lessonNo} = val
                let params = JSON.stringify({
                    live_id: lessonNo,
                    action_type: lessonType,// 1看直播/2看回放/3视频课(类直播)
                })
                this.jumpParams = params
                try{
                    window.webkit.messageHandlers.showNativeLiving.postMessage(params);
                }catch(e){ 
                    try{
                        params = JSON.parse(params)
                        let res = this._quitApp.showNativeLivingIos(params)
                        if(res.isSuccess) console.log('jump success')
                        else this.jump_err()
                    }catch(err){ 
                        this.jump_err()
                    }
                }
            },
            async jump_err(){
                clearTimeout(this.jump_errToast)
                this._common.confirmPro.open({
                    message: `跳转失败，未找到高途客户端`,
                    confirmType: 'c1',
                })
                if(this.jump_errToast) return
                this.jump_errToast = setTimeout(() => {
                    this._common.confirmPro.close()
                },5000)
            },
            // cards数组分割成N个
            splitArr(arr,N){
                var result = []
                for (let i = 0; i < arr.length; i+=N) {
                    result.push(arr.slice(i,i+N))
                }
                return result
            },
            async linkInit(){
                let res = await this.api.configLoad('gaotu_video')
                if(!res || !res.length) return
                res = res[0]
                this.config = res.resourceDetail || {}
            },
            async awardNotice(data, message){
                data = data || {}
                let num = 0
                for(let i in data){
                    if(!this._common.isNumber(data[i])) continue
                    num = Number(data[i]) + num
                }
                if(num > 0){
                    message = message || this.TEXT.video.award
                    message = this._common.urlParse(message, {value: num})
                    await this._common.confirmPro.open({
                        message,
                        confirmType: 'c10 balance-check',
                        defineClass: 'video-award',
                        buttons: [
                            {title: '确定', type: 'confirm'}
                        ]
                    })
                }
                
                this.api.balanceCheck()
            },
            eventSet(){
                this.eventOff()
                document.addEventListener('visibilitychange', this.visibilitychange, true)
                this._common.eventSet('backHome', e => {
                    this.back()
                })
            },
            eventOff(){
                document.removeEventListener('visibilitychange', this.visibilitychange, true)
                this._common.eventRemove('backHome')
            },
            back(){
                this._routeHand.goto({
                    name: 'Home',
                    type: 'replace',
                })
            },
            visibilitychange(){
                // if(this.dataLoading) return
                clearTimeout(this.loadingTimeout)
                this.loadingTimeout = setTimeout(() => {
                    this.stepInfoLoad({noLoad: true, noError: true})
                }, 500)

            }
        },
        created() {
            this.pageInit()
        },
        components: {
            ComponentBcg,
        },
        computed: {
            vedioDisabled(){
                if(this.isPCClient || !this._common.clientAppCheck('gaotu')) return true
                return false
            },
            listNotice(){
                let text = this.pageText['has none video']
                if(this.dataLoading) text = this.pageText['video loading']
                if(this.vedioDisabled) text = this.pageText['PC is not support']
                return text
            },
            isPCClient(){
                let callback = this.appData.getItem('quit_callback')
                return callback
                // return true
            },
            PCLinkeData(){
                let {config: {PC: data}} = this
                if(!data) return {}
                return data
            },
            client(){
                return this._common.getClient()
            },
            debugInfo(){
                let {client} = this
                client = client || 'None'
                let text = [`当前客户端：${client}`]
                let {jumpParams} = this
                if(jumpParams) text.push(`跳转参数：${jumpParams}`)
                return text.join('<br />')
            },
            clazzNo(){
                let {clazzNoList: list} = this
                let val = list[0] || null
                return val
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            headListCul(){
                let {configList: list} = this
                list = this._common.deepCopy(list)
                let rd = []
                for(let i in list){
                    let {resourceDetail} = list[i]
                    rd.push(resourceDetail)
                }
                return rd
            },
            selectCul(){
                let {headListCul: list, headVal: value} = this
                let key = 'courseId'
                let {data} = this._common.selected({list, value, key})
                return data || {}
            },
            listInfoLength(){
                let {listInfo:list} = this
                return list.length
            },
            totalCul(){
                let {total,size} = this
                return Math.ceil(total/size)
            },
        },
        watch: {
            selectCul: {
                handler(val){
                    if(!val || !val.courseId) return
                    this.stepInfoLoad()
                },
                deep: true,
                immediate: true
            },
            $route:{
                handler(){
                    this.headVal = this.$route.params.headVal
                    // this.hash = this.$route.hash
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-cabin-summary {
        @text-blue-color: #60647D;
        @text-yel-color: #C38D76;
        @text-red-color: #A84835;
        @text-deepred-color: #8B1D39;
        @text-grey-color: #414346;

        .text-blue-color {
            color: @text-blue-color;
        }

        .text-yel-color {
            color: @text-yel-color;
        }

        .text-red-color {
            color: @text-red-color;
        }

        .text-deepred-color {
            color: @text-deepred-color;
        }

        .text-grey-color {
            color: @text-grey-color;
        }

        .debug-info {
            position: absolute;
            right: 30px;
            top: 30px;
            color: red;

            span[class^='text'] {
                line-height: 17px;
            }
        }

        .bcg {
            .background-img-max(url('@{assetsUrlV2_0}/cabin/c6.png'));
            position: fixed;
            top: 0;
            left: 0;
            // right: 0;
            bottom: 0;
            margin: auto;
            width: 665px;
            height: 365px;

            .page-left {
                .background-img-max(url('@{assetsUrlV2_0}/cabin/c3.png'));
                position: absolute;
                left: 0;
                bottom: 6px;
                width: 72px;
                height: 319px;
            }

            .page-connect {
                .background-img-max(url('@{assetsUrlV2_0}/cabin/c4.png'));
                position: absolute;
                top: 113px;
                left: 38px;
                width: 69px;
                height: 164px;
                z-index: 3;
            }

            .page-title {
                .background-img-max(url('@{assetsUrlV2_0}/cabin/c9.png'));
                position: absolute;
                left: 90px;
                top: 2px;
                width: 116px;
                height: 80px;

                .title {
                    position: relative;
                    top: 13px;
                }
            }

            .page-go {
                position: absolute;
                left: 308px;
                top: 5px;
                display: flex;

                .item-1 {
                    cursor: pointer;
                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c11.png'));
                    width: 80px;
                    height: 63px;

                    .title {
                        position: relative;
                        top: 11px;
                    }
                }

                .item-2 {
                    cursor: pointer;
                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c11.png'));
                    width: 80px;
                    height: 63px;
                    margin-right: -1px;

                    .title {
                        position: relative;
                        top: 11px;
                    }
                }

                .item-3 {
                    cursor: pointer;
                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c12.png'));
                    width: 80px;
                    height: 71px;

                    .title {
                        position: relative;
                        top: 11px;
                    }
                }

                .head-item-x.active {
                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c10.png'));
                    position: relative;
                    top: -4px;

                    .title {
                        position: relative;
                        top: 13px;
                    }

                    .text-box {
                        color: #9C6233;
                    }
                }
                &.l4 {
                    left: 271px;
                    .item-1 {
                        width: 70px;
                        .title {
                            top: 9px;
                            >span{
                                font-size: 13px;
                            }
                        }
                    }
                    .item-2 {
                        width: 70px;
                        margin-right: -1px;
                        .title {
                            position: relative;
                            top: 9px;
                            >span{
                                font-size: 13px;
                            }
                        }
                    }
                    .item-3 {
                        width: 70px;
                        height: 71px;
                        margin-right: -1px;
                        .title {
                            position: relative;
                            top: 9px;
                            >span{
                                font-size: 13px;
                                white-space: nowrap;
                            }
                        }
                    }
                    .item-4 {
                        cursor: pointer;
                        .background-img-max(url('@{assetsUrlV2_0}/cabin/c12.png'));
                        width: 70px;
                        height: 77px;
                        .title {
                            position: relative;
                            top: 11px;
                            >span{
                                font-size: 12px;
                                white-space: nowrap;
                            }
                        }
                    }
                    .head-item-x.active {
                        .background-img-max(url('@{assetsUrlV2_0}/cabin/c10.png'));
                        position: relative;
                        top: -4px;
                        .title {
                            position: relative;
                            top: 13px;
                        }
                        .text-box {
                            color: #9C6233;
                        }
                    }
                }
            }

            .page-center {
                z-index: 2;
                .background-img-max(url('@{assetsUrlV2_0}/cabin/c7.png'));
                position: absolute;
                left: 72px;
                bottom: 6px;
                width: 514px;
                height: 322px;

                .page-bottom-shadow {
                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c2.png'));
                    position: absolute;
                    top: 30px;
                    left: 40px;
                    width: 435px;
                    height: 258px;

                    .content-box {
                        width: 435px;
                        height: 258px;
                        // display: flex;
                        // flex-direction: column;
                        // justify-content: space-between;
                        // align-items: center;
                        padding: 11px 10px 9px 19px;

                        .list-shadow:first-child {
                            margin-top: 0;
                        }

                        .list-shadow {
                            .background-img-max(url('@{assetsUrlV2_0}/cabin/c13.png'));
                            width: 406px;
                            height: 53px;
                            position: relative;
                            margin-top: 9px;

                            .list-box {
                                .background-img-max(url('@{assetsUrlV2_0}/cabin/c14.png'));
                                width: 327px;
                                height: 37px;
                                position: absolute;
                                top: 5px;
                                bottom: 11px;
                                left: 38px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .list-box-left {
                                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c16_left.png'));
                                    width: 53px;
                                    height: 45px;
                                    position: absolute;
                                    top: -4px;
                                    left: -36px;
                                }

                                .list-box-right {
                                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c16_right.png'));
                                    width: 53px;
                                    height: 45px;
                                    position: absolute;
                                    top: -4px;
                                    right: -35px;
                                }

                                .title {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding-top: 1px;
                                    position: relative;
                                    height: 100%;
                                    width: 50%;

                                    .txt {
                                        font-size: 14px;
                                        color: #626079;
                                        width: 128px;
                                    }

                                    .list-left {
                                        // .background-img-max(url('@{assetsUrlV2_0}/cabin/c16_left.png'));
                                        z-index: 1;
                                        width: 53px;
                                        height: 45px;
                                        position: absolute;
                                        top: -4px;
                                        left: -36px;

                                        .l-txt {
                                            display: inline-block;
                                            font-size: 12px;
                                            transform: scale(0.6);
                                            position: absolute;
                                            bottom: 1px;
                                            left: 15px;
                                            right: 15px;
                                            margin: auto;
                                            text-align: center;
                                            white-space: nowrap;
                                        }

                                        .l-rate {
                                            display: inline-block;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 13px;
                                            left: 7px;
                                            right: 12px;
                                            text-align: center;
                                            white-space: nowrap;
                                        }
                                    }

                                    .list-right {
                                        z-index: 1;
                                        // .background-img-max(url('@{assetsUrlV2_0}/cabin/c16_right.png'));
                                        width: 53px;
                                        height: 45px;
                                        position: absolute;
                                        top: -4px;
                                        right: -35px;

                                        .r-txt {
                                            display: inline-block;
                                            font-size: 12px;
                                            transform: scale(0.6);
                                            position: absolute;
                                            bottom: 1px;
                                            left: 23px;
                                            right: 23px;
                                            margin: auto;
                                            text-align: center;
                                            white-space: nowrap;
                                        }

                                        .r-rate {
                                            display: inline-block;
                                            font-size: 13px;
                                            position: absolute;
                                            top: 13px;
                                            left: 13px;
                                            right: 7px;
                                            text-align: center;
                                            white-space: nowrap;
                                        }
                                    }

                                    .list-left.on {
                                        .background-img-max(url('@{assetsUrlV2_0}/cabin/c15_left.png'));
                                    }

                                    .list-right.on {
                                        .background-img-max(url('@{assetsUrlV2_0}/cabin/c15_right.png'));
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .page-right {
                z-index: 1;
                .background-img-max(url('@{assetsUrlV2_0}/cabin/c5.png'));
                position: absolute;
                top: 66px;
                right: 25px;
                width: 65px;
                height: 228px;

                .right-go {
                    .background-img-max(url('@{assetsUrlV2_0}/cabin/c8.png'));
                    width: 64px;
                    height: 120px;
                    position: absolute;
                    top: -20px;
                    right: -20px;
                }
            }
        }

        // 滚动条
        .mini-scrollbar::-webkit-scrollbar {
            width: 8px;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }

        .mini-scrollbar-x::-webkit-scrollbar {
            height: 4px;
        }

        .mini-scrollbar::-webkit-scrollbar-thumb,
        .mini-scrollbar-x::-webkit-scrollbar-thumb {
            border-radius: 10px;
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            // background: rgba(0,0,0,0.2);
            .background-img-max(url('@{assetsUrlV2_0}/certificate/c6.png'));
            // background: #FFFAE6;
            // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
            // background-position: center;

        }

        .mini-scrollbar::-webkit-scrollbar-track-piece {
            // background: red;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }

        .mini-scrollbar::-webkit-scrollbar-thumb::before {
            content: '';
            .background-img-max(url('@{assetsUrlV2_0}/task/c12.png'));
        }

        .mini-scrollbar::-webkit-scrollbar-track,
        .mini-scrollbar-x::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 5px #C7946A;
            border-radius: 10px;
            // background: rgba(178,123,85,0.1);
            // background: #B27B55;
            background-image: linear-gradient(to right, #D39F8A, #DFAD95, #D39F8A);
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }

        .mini-scrollbar {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    .com-confirm-pro{
        .video-award{
            .gotu-confirm-pro{
                text-align: left;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 40px;
                .pro-message{
                    height: auto;
                    line-height: 17px;
                }
            }
        }
    }
</style>