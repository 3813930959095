import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'mistakes'
        let name = 'mistakes'
        opt = {localKey, name}
        super(opt)
    }
    async wrongProgress(opt) {
        let {user_code} = opt
        let options = {
            method: "get",
            user_code,
            data:{
            }
        }
        return await this.requestAction('wrongProgress', options)
    }
    async wrongList(opt) {
        let {user_code} = opt 
        let options = {
            method: "get",
            user_code:user_code,
            data:{
            }
        }
        return await this.requestAction('wrongList', options)
    }
    async exerciseList(opt) {
        let {user_code, exercise_type} = opt 
        let data = {}
        if(exercise_type) data = {exercise_type}
        let options = {
            method: "get",
            user_code:user_code,
            data
        }
        let res = await this.requestAction('wrongList', options)
        if(!res) return res
        return this._common.mindExerciseHandler(res)
    }
    async uploadWrongCourse(opt) {
        let {user_code,exercise_id,user_answer,exercise_source} = opt
        let options = {
            method: "post",
            user_code:user_code,                    
            data:{ 
                exercise_id:exercise_id,
                user_answer:user_answer,
                exercise_source:exercise_source,
            }
        }
        return this.requestAction('uploadWrongCourse', options)
    }
    async exerciseUpload(opt) {
        let {user_code, exercise_id, user_answer, exercise_source, correct, answer_note_id, group_id} = opt
        let options = {
            method: "post",
            user_code:user_code,                    
            data:{ 
                exercise_id,
                user_answer,
                exercise_source,
                correct,
                answer_note_id,
                group_id
            }
        }
        return this.requestAction('uploadWrongCourse', options)
    }
    async getAllStatistics(opt) {
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getAllStatistics', options)
    }
    async getSimilarList(opt) {
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getSimilarList', options)
    }
    async getAiSimilarList(opt) {
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getAiSimilarList', options)
    }
    async postSimilarAnswer(opt) {
        opt = opt || {}
        let options = {
            method: "post",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('postSimilarAnswer', options)
    }
    async getStatistics(opt) {
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getStatistics', options)
    }
    async getWrongList(opt){
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getWrongList', options)
    }
    async getMistakesList(opt){
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getMistakesList', options)
    }
    async getRecord(opt) {
        let {userCode} = opt || {}
        userCode = userCode || this._appData.getItem('usercode', 'app')
        if(!userCode) return false
        let options = {
            method: "get",
            user_code: userCode,                
            data:{ }
        }
        return this.requestAction('getRecord', options)
    }
    async getGoodnews(opt) {
        let {userCode,group_id} = opt || {}
        userCode = userCode || this._appData.getItem('usercode', 'app')
        if(!userCode || group_id == null) return
        let options = {
            method: "get",
            user_code: userCode,                
            data:{ 
                group_id: group_id,
            }
        }
        return this.requestAction('getGoodnews', options)
    }
    async getMistakesRecord(opt){
        opt = opt || {}
        let options = {
            method: "get",
            ...opt,                
            data:{ 
                ...opt
            }
        }
        return this.requestAction('getMistakesRecord', options)
    }
}
